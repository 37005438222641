<template>
  <div id="index">
    <div class="display-flex jc-space-between align-items-center">
      <div>
        <span class="fw-300 fs-26"><span class="m-f-color fs-40">Have</span> a <span class="m-f-color fs-40">nice</span> day</span>
      </div>  
      <router-link to="/jobSet"><i class="el-icon-plus fs-26 m-f-color"></i></router-link>
    </div>
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" class="mt-10">
          <div class="module-title align-center">Add by day</div>
          <div class="mt-10 display-flex align-items-center jc-center"  v-for="item,index in list.add" v-bind:key="index" v-if="item.type=='day' && !item.current_end_flag">
            <div class="list-item cursor-pointer" @click="finishCurrentJob(item)">
              <div class="item-check z-index-2" v-if="item.current_end_flag">
                <i class="el-icon-check"></i>
              </div>
              <div class="add-line" :style="'width:'+item.p+'%;'"></div>
              <div class="w-color z-index-2" >
                <div class="fs-16">{{ item.title }}</div>
                <div class="fs-12 mt-6" v-if="item.p<100">已连续完成<span class="s-font">{{item.success_days}}</span>天，还剩{{item.left_days}}天</div>
                <div class="fs-12 mt-6" v-else>目标达成,点个赞！</div>
              </div>
            </div>
          </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" class="mt-10">
        <div class="module-title align-center">Subtract by day</div>
        <div class="mt-10 display-flex align-items-center jc-center"  v-for="item,index in list.subtract" v-bind:key="index" v-if="item.type=='day' && !item.current_end_flag">
            <div class="list-item cursor-pointer" @click="finishCurrentJob(item)">
              <div class="item-check z-index-2" v-if="item.current_end_flag">
                <i class="el-icon-check"></i>
              </div>
              <div class="subtract-line" :style="'width:'+item.p+'%;'"></div>
              <div class="w-color z-index-2">
                <div class="fs-16">{{ item.title }}</div>
                <div class="fs-12 mt-6" v-if="item.p<100">已连续完成<span class="s-font">{{item.success_days}}</span>天，还剩{{item.left_days}}天</div>
                <div class="fs-12 mt-6" v-else>目标达成,点个赞！</div>
              </div>
            </div>
          </div>
      </el-col>

      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" class="mt-10">
        <div class="module-title align-center">今日完成</div>
          <div class="mt-10 display-flex align-items-center jc-center"  v-for="item,index in list.add" v-bind:key="index" v-if="item.type=='day' && item.current_end_flag">
            <div class="list-item cursor-pointer" @click="finishCurrentJob(item)">
              <div class="item-check z-index-2" v-if="item.current_end_flag">
                <i class="el-icon-check"></i>
              </div>
              <div class="add-line" :style="'width:'+item.p+'%;'"></div>
              <div class="w-color z-index-2" >
                <div class="fs-16">{{ item.title }}</div>
                <div class="fs-12 mt-6" v-if="item.p<100">已连续完成<span class="s-font">{{item.success_days}}</span>天，还剩{{item.left_days}}天</div>
                <div class="fs-12 mt-6" v-else>目标达成,点个赞！</div>
              </div>
            </div>
          </div>
          <div class="mt-10 display-flex align-items-center jc-center"  v-for="item,index in list.subtract" v-bind:key="index" v-if="item.type=='day' && item.current_end_flag"">
            <div class="list-item cursor-pointer" @click="finishCurrentJob(item)">
              <div class="item-check z-index-2" v-if="item.current_end_flag">
                <i class="el-icon-check"></i>
              </div>
              <div class="subtract-line" :style="'width:'+item.p+'%;'"></div>
              <div class="w-color z-index-2">
                <div class="fs-16">{{ item.title }}</div>
                <div class="fs-12 mt-6" v-if="item.p<100">已连续完成<span class="s-font">{{item.success_days}}</span>天，还剩{{item.left_days}}天</div>
                <div class="fs-12 mt-6" v-else>目标达成,点个赞！</div>
              </div>
            </div>
          </div>
      </el-col>
    </el-row>

    <el-row :gutter="20" class="mt-50">
      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" class="mt-10">
          <div class="module-title align-center">Maximum duration days</div>
          <div class="mt-10 display-flex align-items-center jc-center"  v-for="item,index in last_list" v-bind:key="index" v-if="item.type=='day'">
            <div class="list-item cursor-pointer" :style="'background-color:'+color_list[index%color_list.length]">
              <div class="w-color z-index-2" >
                <div class="fs-16">{{ item.title }}</div>
                <div class="fs-12 mt-6">最大持续<span class="s-font">{{item.max_last_days}}</span>天</div>
              </div>
            </div>
          </div>
      </el-col>

      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" class="mt-10">
          <div class="module-title align-center">Add by week</div>
          <div class="mt-10 display-flex align-items-center jc-center"  v-for="item,index in list.add" v-bind:key="index" v-if="item.type=='week'">
            <div class="list-item cursor-pointer" @click="finishCurrentJob(item)">
              <div class="item-check z-index-2" v-if="item.current_end_flag">
                <i class="el-icon-check"></i>
              </div>
              <div class="add-line" :style="'width:'+item.p+'%;'"></div>
              <div class="w-color z-index-2" >
                <div class="fs-16">{{ item.title }}</div>
                <div class="fs-12 mt-6" v-if="item.p<100">已连续完成<span class="s-font">{{item.success_days}}</span>周，还剩{{item.left_days}}周</div>
                <div class="fs-12 mt-6" v-else>目标达成,点个赞！</div>
              </div>
            </div>
          </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" class="mt-10">
        <div class="module-title align-center">Subtract by week</div>
        <div class="mt-10 display-flex align-items-center jc-center"  v-for="item,index in list.subtract" v-bind:key="index" v-if="item.type=='week'">
            <div class="list-item cursor-pointer" @click="finishCurrentJob(item)">
              <div class="item-check z-index-2" v-if="item.current_end_flag">
                <i class="el-icon-check"></i>
              </div>
              <div class="subtract-line" :style="'width:'+item.p+'%;'"></div>
              <div class="w-color z-index-2">
                <div class="fs-16">{{ item.title }}</div>
                <div class="fs-12 mt-6" v-if="item.p<100">已连续完成<span class="s-font">{{item.success_days}}</span>周，还剩{{item.left_days}}周</div>
                <div class="fs-12 mt-6" v-else>目标达成,点个赞！</div>
              </div>
            </div>
          </div>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import {apiGetJobList, apiFinishCurrentDayJob, apiGetMaxLastDays} from '@/api/job/job'

export default {
  name: "Index",
  data() {
      return {
        list: {
          add: [],
          subtract: []
        },
        last_list:[],
        color_list: ['#e6e4bd', '#95bde0', '#73abcd', '#ed6a3f', '#ecaa76', '#e3e5ef', 
        '#a8d2e4', '#62a9c4', '#f2cfd6', '#f9f7e6', '#D8D8BF']
      }
    },
  methods: {
      getJobList() {
        apiGetJobList().then(res => {
          if (res.data.err_no == 0) {
            this.list.add = res.data.results.add;
            this.list.subtract = res.data.results.subtract
          }
        })
      },
      getMaxLastDays() {
        apiGetMaxLastDays().then(res => {
          if (res.data.err_no == 0) {
            this.last_list = res.data.results;
          }
        })
      },
      finishCurrentJob(item) {
        if (item.current_end_flag) {
          return false;
        }

        apiFinishCurrentDayJob({job_id:item.id}).then(res => {
          if (res.data.err_no == 0) {
            this.getJobList();
          }
        })
      }
    },
  mounted() {
    this.getJobList();
    this.getMaxLastDays();
  }
}
</script>

<style scoped>
#index {
  padding:0 20px;
  color:#515a6e;
  font-weight: 300;
}

.module-title {
  margin-bottom:20px;
  font-size: 26px;
  font-weight: 300;
}
.list-item {
  background-color:#c5c8ce;
  width:560px;
  min-height:60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.s-font {
  font-weight: bold; 
  font-size:14px;
  padding:0 6px;
}

</style>

